import React, { useState, useEffect, useRef } from 'react'
import Header from '../../layout/header'
import Footer from '../../layout/footer'
import FooterBanner from './footer-banner'
import Banner from './banner'
import Features from './features'
import Testimonials from './testimonials'
import AccessibilityProfiles from '../../AccessibilityProfiles';

function Home() {
  const [accesability, setAccesibilty] = useState(false);
  const toggleAccessibility = () => {
    if (accessibilityRef.current) {
      accessibilityRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    setAccesibilty(!accesability);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const accessibilityRef = useRef(null);

  return (
    <div className='home-wrapper main-wrapper main-wrapper-cms'>
      {/* <div className='top-header'>
      </div> */}
      <div>
        <div className='gradient-wrap'>
          {accesability &&
            <AccessibilityProfiles ref={accessibilityRef}/>
          }
          <Header/>
          <div className="container">
            <Banner accesability={accesability} toggleAccessibility={toggleAccessibility} />
          </div>
        </div>
        <Features/>
      </div>
      <Testimonials/>
      <FooterBanner/>
      <Footer/>
    </div>
  )
}

export default Home;