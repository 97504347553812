import React, { useState, useEffect } from 'react';

const AccessibilityProfiles = () => {
  const [activeProfile, setActiveProfile] = useState('default');
  const [focusPosition, setFocusPosition] = useState(0);

  // Style profiles
  const profiles = {
    SeizureSafe: {
      filter: 'brightness(0.95) saturate(0.9)',
      transition: 'all 0.3s ease',
      backgroundColor: '#1a1a1a',
      '--theme-color': '#ff8fa6',
      '--header-color': '#a0a0a0',
      '--pink-btn': '#ff8fa6',
      '--black-bg': '#1a1a1a',
      animation: 'none',
    },
    
    VisionImpaired: {
      filter: 'brightness(1.1) contrast(1.2)',
      fontSize: '120%',
      lineHeight: '1.6',
      letterSpacing: '0.12em',
      wordSpacing: '0.16em',
      '--theme-color': '#ff4270',
      '--header-color': '#ffffff',
    },
    
    ColorBlind: {
      filter: 'saturate(1.5) contrast(1.1)',
      '--theme-color': '#ffb347', // High visibility orange
      '--header-color': '#4d94ff', // Distinguishable blue
      '--pink-btn': '#ffb347',
    },
    
    Adhd: {
      filter: 'brightness(0.98)',
      lineHeight: '1.8',
      letterSpacing: '0.05em',
      cursor: 'default',
      position: 'relative',
    },
    
    Dyslexia: {
      fontFamily: 'OpenDyslexic, Arial, sans-serif',
      lineHeight: '1.6',
      letterSpacing: '0.1em',
      wordSpacing: '0.15em',
      backgroundColor: '#f5f5f5',
      color: '#333333',
    },
    
    CognitiveDisability: {
      lineHeight: '1.8',
      letterSpacing: '0.08em',
      wordSpacing: '0.16em',
      '& h1, & h2, & h3, & h4, & h5, & h6': {
        backgroundColor: 'rgba(255, 255, 0, 0.2)',
        padding: '0.5em',
        margin: '1em 0',
      },
    }
  };

  // Focus bar component for ADHD and Cognitive profiles
  const FocusBar = () => (
    <div 
      className="fixed w-full h-16 bg-yellow-100/20 transition-all duration-300"
      style={{ top: `${focusPosition}px` }}
    />
  );

  useEffect(() => {
    // Update focus bar position on scroll for ADHD and Cognitive profiles
    if (activeProfile === 'adhd' || activeProfile === 'cognitiveDisability') {
      const handleScroll = () => {
        setFocusPosition(window.scrollY + (window.innerHeight / 2));
      };
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }
  }, [activeProfile]);

  // Apply styles to document root
  useEffect(() => {
    const root = document.documentElement;
    const selectedProfile = profiles[activeProfile] || {};
    
    // Reset all styles first
    Object.keys(profiles).forEach(profile => {
      Object.keys(profiles[profile]).forEach(property => {
        root.style.removeProperty(property);
      });
    });
    
    // Apply new profile styles
    Object.entries(selectedProfile).forEach(([property, value]) => {
      root.style.setProperty(property, value);
    });
    
    // Handle special cases
    if (activeProfile === 'dyslexia') {
      // Load OpenDyslexic font if not already loaded
      const fontLink = document.createElement('link');
      fontLink.href = 'https://cdnjs.cloudflare.com/ajax/libs/OpenDyslexic/1.0.3/OpenDyslexic-Regular.woff';
      fontLink.rel = 'stylesheet';
      document.head.appendChild(fontLink);
    }
  }, [activeProfile]);

  return (
    <div className="fixed right-4 top-4 z-50 accesibilty-settings">
      <div className="rounded-lg shadow-lg p-4">
        <h3 className="text-lg font-bold mb-4 text-center">Accessibility Profiles</h3>
        
        <div className="accesability-outer">
          {Object.keys(profiles).map((profile) => (
            <button
              key={profile}
              onClick={() => setActiveProfile(profile)}
              className={`w-full px-4 py-2 rounded accesible-btns ${
                activeProfile === profile 
                  ? 'bg-blue-500 ' 
                  : 'bg-gray-100 hover:bg-gray-200'
              }`}
            >
              {profile.replace(/([A-Z])/g, ' $1').trim()}
            </button>
          ))}
          
          <button
            onClick={() => setActiveProfile('default')}
            className={`w-full px-4 py-2 rounded accesible-btns ${
              activeProfile === 'default' 
                ? 'bg-blue-500 ' 
                : 'bg-gray-100 hover:bg-gray-200'
            }`}
          >
            Default
          </button>
        </div>
      </div>
      
      {(activeProfile === 'adhd' || activeProfile === 'cognitiveDisability') && (
        <FocusBar />
      )}
    </div>
  );
};

export default AccessibilityProfiles;