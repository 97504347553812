import React, { useState, useEffect } from 'react'
import Header from '../../layout/header'
import Footer from '../../layout/footer'
import FooterBanner from './footer-banner'
import Tiwari from '../../assets/images/Shray-rai-tiwari.png'
import Bala from '../../assets/images/bala.png'
import Prakash from '../../assets/images/prakash.png'
import Abtimg from "../../assets/images/about-img.png"

function Home() {
  return (
    <div className='home-wrapper main-wrapper  main-wrapper-cms'>
      <div>
        <div className='gradient-wrap'>
          <Header />
          <div className='container'>
            <div className='abut-wrap mb-lg-5'>
              <div className='about-head'>About <span className='text-pink'>Us</span></div>
              <div className='about-para'>
                <p>
                  In a world where great stories often get lost in the chaos of complex tools and endless tasks,<br/>
                  FlickVid is here to simplify content creation.</p>
                <p>
                  We understand the frustration behind low content quality, creative blocks, and long turnaround times.<br/>
                  But what if content creation could be different- easy, fun, and powerful?
                </p>
              </div>
              <div>
                <div className='text-pink about-pink-large'>FlickVid is the answer.</div>
                <div className="row">
                  <div className='about-para col-12 col-lg-6'>
                    <p>Born from the shared vision of two creators, a storyteller- Shray, and a tech visionary-  bala, our platform transforms content creation into a seamless experience.</p>
                    <p> Combining cutting-edge AI with real-world expertise, we break down every barrier between your ideas and the screen. Whether you’re a seasoned influencer or just starting, we give you the tools to make your story come alive.</p>
                    <p> Auto-generated scripts, dynamic shot breakdowns, specialised content camera with love audio feedback- it’s all here, packed into one powerful tool designed for creators by creators.</p>
                  </div>
                  <div className='col-12 col-lg-6'>
                    <div className='text-center'>
                      <img src={Abtimg} alt="Abtimg" className='img-fluid about-image-outer' width="360" height="360"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          
          </div>
        </div>
        <div className="container">
        <div className="row mt-5">
              <div className="col-12 col-lg-6">
                <div>
                  <div className='our-section'>Our <span className='text-pink'>Vision</span></div>
                  <div className='about-para'>
                    To empower every smartphone user to produce cinematic content that leaves a lasting impression.
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div>
                  <div className='our-section'>Our
                    <span className='text-pink'> Mission</span>
                  </div>
                  <div className='about-para'>
                    To provide a revolutionary, easy-to-use content creation platform that simplifies the process while delivering professional-quality results.
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className='abt-meet'>Meet <span className='text-pink'>our Founders</span></div>
              <div className='col-12 col-lg-6 mt-lg-5'>
                <div className='text-center'>
                  <img src={Tiwari} alt="Tiwari" className='img-fluid blend-img' width="80%" height="300"/>
                </div>
                <div className='text-center '>
                  <div className='founder-name'>Shray Rai Tiwari</div>
                  <div className='founder-position'>Founder, FlickVid </div>
                </div>
              </div>
              <div className='col-12 col-lg-6 about-para my-5 '>
                <p>
                  “Good content is like a bridge - it presents stories that bring us all together. My aim with FlickVid is to help you create that content.”
                </p>
                <p>
                  As an influencer with 200K+ following on Instagram, Shray’s passion for content creation shines through in every project he touches. With standout roles in films like Shab (Zee5), Guilty (Netflix), Shoorveer (Disney+ Hotstar), and more, he has mastered the art of engaging audiences.
                </p>
                <p>
                  Having worked with brands like Myntra, Swiggy, Bumble and more, Shray has cracked the code for what it means to create engaging content.
                </p>
                <p>
                  His passion for storytelling is the creative heartbeat of FlickVid, helping others craft compelling content with ease.
                </p>
              </div>
              <div className='col-12 col-lg-6 my-5 mobile-only'>
                <div className='text-center'>
                  <img src={Bala} alt="Bala" className='img-fluid blend-img' width="80%" height="300"/>
                </div>
                <div className='text-center '>
                  <div className='founder-name'>Balaji jaganathan
                  </div>
                  <div className='founder-position'>Co-Founder, FlickVid  </div>
                </div>
              </div>
              <div className='col-12 col-lg-6 about-para my-lg-5 pt-lg-5'>
                <p>
                “Innovation isn’t about complexity, it’s about making the impossible feel effortless.  At FlickVid, that’s exactly what we do.”
                </p>
                <p>
                bala’s unshakable belief in the potential of every creator is at the heart of FlickVid. As a tech enthusiast and business builder, he’s passionate about using technology to create new possibilities across industries.
                </p>
                <p>
                His deep expertise drives the innovative AI tools that power FlickVid, transforming complex content creation into a seamless, accessible experience for all.
                </p>
              </div>
              <div className='col-12 col-lg-6 my-5 desktop-only'>
                <div className='text-center'>
                  <img src={Bala} alt="Bala" className='img-fluid blend-img' width="80%" height="300"/>
                </div>
                <div className='text-center '>
                  <div className='founder-name'>Balaji jaganathan
                  </div>
                  <div className='founder-position'>Co-Founder, FlickVid  </div>
                </div>
              </div>
              <div className='col-12 col-lg-6 my-5'>
                <div className='text-center'>
                  <img src={Prakash} alt="Prakash" className='img-fluid blend-img' width="80%" height="300"/>
                </div>
                <div className='text-center '>
                  <div className='founder-name'>Prakash Satyapalan
                  </div>
                  <div className='founder-position'>Tech Adviser </div>
                </div>
              </div>
              <div className='col-12 col-lg-6 about-para mt-lg-5 pt-lg-5'>
                <p>
                "At FlickVid, we’re all about making creativity easy. Our goal is to help creators bring their ideas to life without any hassle!”
                </p>
                <p>
                Meet Prakash, the tech wizard behind FlickVid! He brings over 20 years of experience from giants like eBay and IBM.
                </p>
                <p>
                With a Master's in Computer Science and a knack for turning complex ideas into sleek solutions, he ensures our app is not just functional but a delight to use. When it comes to tech, Prakash doesn’t just keep up with the pace- he sets it!
                </p>
               
              </div>
            </div>
            
        </div>
      </div>
      <FooterBanner />
      <Footer />
    </div>
  )
}

export default Home