import React, {useState, useEffect} from "react";
import Main from "../../assets/images/main.png";
import Main1 from "../../assets/images/main (1).png";
import Main2 from "../../assets/images/main2.png";
import Main3 from "../../assets/images/main3.png";

function Features() {
	return (
		<div className="container">
			<div className="row feature-card">
				<div className="col-md-11 feature-wrap left-border my-5">
					<div className="row ">
						<div className="col p-lg-5 p-4 d-flex flex-column position-static justify-content-center">
							<div className="mb-0 feature-head">Brain freezeee on ideas?</div>
							<p className="card-text">Try our personalized script engine & create content that screams ‘YOU’!</p>
							<a href="#" className="stretched-link">
								Know More{" "}
							</a>
						</div>
						<div className="col-auto pt-5 feature-img">
							<img src={Main} alt="Main" className="img-fluid pe-lg-5" width="350" height="auto" />
						</div>
					</div>
				</div>
				<div className="col-md-11 feature-wrap right-border mb-5">
					<div className="row ">
						<div className="col p-lg-5 p-4 d-flex flex-column position-static justify-content-center">
							<div className="mb-0 feature-head">Need a pre-made awesome script?</div>
							<p className="card-text">We've got a library full of them for you to claim it!</p>
							<a href="#" className="stretched-link">
								Know More{" "}
							</a>
						</div>
						<div className="col-auto feature-img">
							<img src={Main1} alt="Main" className="img-fluid pe-lg-5" width="350" height="auto" />
						</div>
					</div>
				</div>
				<div className="col-md-11 feature-wrap left-border mb-5">
					<div className="row ">
						<div className="col p-lg-5 p-4 d-flex flex-column position-static justify-content-center">
							<div className="mb-0 feature-head">Content creation taking forever?</div>
							<p className="card-text">Get detailed shot breakdowns to make top quality content!</p>
							<a href="#" className="stretched-link">
								Know More{" "}
							</a>
						</div>
						<div className="col-auto feature-img">
							<img src={Main3} alt="Main" className="img-fluid pe-lg-5" width="350" height="auto" />
						</div>
					</div>
				</div>
				<div className="col-md-11 feature-wrap right-border mb-">
					<div className="row ">
						<div className="col p-lg-5 p-4 d-flex flex-column position-static justify-content-center">
							<div className="mb-0 feature-head">Clueless about shooting cinematic angles?</div>
							<p className="card-text">Get the perfect angles with our content sensitive camera!</p>
							<a href="#" className="stretched-link">
								Know More{" "}
							</a>
						</div>
						<div className="col-auto pb-5 feature-img">
							<img src={Main2} alt="Main" className="img-fluid pe-lg-5" width="350" height="auto" />
						</div>
					</div>
				</div>
				<div className="col-12 text-center">
					<div className="all-in-one-place">All in one place!</div>
					<div className="text-center mb-5">
						<div className="download-app-btn">Try Now</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Features;
