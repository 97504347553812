import React, { useState, useEffect } from 'react'
import Header from '../../layout/header'
import Footer from '../../layout/footer'
import FooterBanner from './footer-banner'
import ContactForm from './contact-form'
import ContactImg from '../../assets/images/contact.png'

function ContactUs() {
  return (
    <div className='home-wrapper main-wrapper  main-wrapper-cms'>
      <div>
        <div className='gradient-wrap'>
          <Header />
         
         
       
        <div className='container'>
       
          <div className='mt-5'>          
            <div className='our-mission'>
            <span className='text-pink'> Get in </span> Touch
            </div>
           <ContactForm/>
          </div>

        </div>
        </div>
      </div>

      <FooterBanner />
      <Footer />
    </div>
  )
}

export default ContactUs