import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Search from "../assets/images/search.png";
import LogoImg from "../assets/images/logo.png";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoCloseOutline } from "react-icons/io5";

function Header() {
    const location = useLocation(); // Get current route path

    const menuClick = () => {
        document.getElementById("navbarSupportedContent").classList.toggle("Show");
        document.getElementById("navbarSupportedContent").classList.toggle("hide");
    };

    const isActive = (path) => location.pathname === path; // Check if path matches current route

    return (
        <header>
            <nav className="navbar navbar-expand-lg py-0">
                <div className="container">
                    <Link className="navbar-brand py-0" to="/">
                        <img src={LogoImg} className="logo-img" alt="Logo" width={100} />
                    </Link>
                    <button
                        className="navbar-toggler"
                        type="button"
                        onClick={menuClick}
                    >
                        <RxHamburgerMenu className="navbar-toggler-icon" />
                    </button>
                    <div className="navbar-collapse hide" id="navbarSupportedContent">
                        <IoCloseOutline className="close-icon" onClick={menuClick} />
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link
                                    className={`nav-link ${isActive("/flickvid-labs") ? "active" : ""}`}
                                    to="/flickvid-labs"
                                >
                                    FlickVid Labs
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    className={`nav-link ${isActive("/about") ? "active" : ""}`}
                                    to="/about"
                                >
                                    About Us
                                </Link>
                            </li>
                            {/* <li className="nav-item">
                                <Link
                                    className={`nav-link ${isActive("/media") ? "active" : ""}`}
                                    to="/media"
                                >
                                    Media
                                </Link>
                            </li> */}
                            <li className="nav-item">
                                <Link
                                    className={`nav-link ${isActive("/contact") ? "active" : ""}`}
                                    to="/contact"
                                >
                                    Contact Us
                                </Link>
                            </li>
                            {/* <li className="nav-item">
                                <Link className="nav-link px-0" to="/search">
                                    <img src={Search} alt="accessibility" width={10} height={10} />
                                </Link>
                            </li> */}
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );
}

export default Header;
