import React, {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import FooterBanner from "./footer-banner";

const DeleteAccountWeb = () => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [showConfirmDeletion, setShowConfirmDeletion] = useState(false);
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [loading, setLoading] = useState(false);
	const [consentChecked, setConsentChecked] = useState(false);
	const [countdown, setCountdown] = useState(10);
	const [referenceId, setReferenceId] = useState("");

	const navigate = useNavigate();

	useEffect(() => {
		let timer;
		if (showConfirmDeletion && countdown > 0) {
			timer = setInterval(() => {
				setCountdown((prev) => prev - 1);
			}, 1000);
		} else if (countdown === 0) {
			navigate("/");
		}
		return () => clearInterval(timer);
	}, [showConfirmDeletion, countdown, navigate]);

	const handleDeleteAccount = async (e) => {
		e.preventDefault();
		if (!password || !consentChecked) return;

		setLoading(true);
		try {
			const response = await axios.post("https://mqpzij5tcf.execute-api.ap-south-1.amazonaws.com/dev", {
				functionName: "deleteUserAccount",
				password,
				username,
			});

			if (response.data.success === "1") {
				setReferenceId(response.data.referenceId);
				localStorage.clear();
				setUsername("");
				setPassword("");
				setIsModalOpen(false);
				setShowConfirmDeletion(true);
			}
		} catch (error) {
			console.error("Delete account error:", error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className="home-wrapper main-wrapper  main-wrapper-cms">
			<div className="gradient-wrap">
				<Header />
			</div>
			<div className="container text-white mb-5">
				<div className="max-w-3xl mx-auto py-12 px-4">
					{/* Main Content */}
					<div className="space-y-8">
						<h1 className="text-3xl font-bold text-center mb-5">Delete Your Account</h1>
						{/* Warning Card */}
						<div className="bg-gradient-to-r from-red-500 to-red-800 p-[1px] rounded-lg">
							<div className="bg-gray-900 rounded-lg p-6">
								<h2 className="text-xl font-semibold text-red-500 mb-4">Account Deletion Process</h2>

								<div className="space-y-4">
									<div className="mb-4">
										<h3 className="text-lg font-semibold text-gray-300 mb-2">30-Day Recovery Period</h3>
										<p className="text-gray-300">When you request account deletion, your account will enter a 30-day recovery period. During this time:</p>
										<ul className="list-disc pl-5 space-y-2 text-gray-300 mt-2">
											<li>Your account will be deactivated but not permanently deleted</li>
											<li>You can recover your account by simply logging in</li>
											<li>All your data will be preserved during this period</li>
										</ul>
									</div>

									<div className="mb-4">
										<h3 className="text-lg font-semibold text-gray-300 mb-2">After 30 Days</h3>
										<p className="text-gray-300">If you don't log in during the recovery period, your account will be permanently deleted, including:</p>
										<ul className="list-disc pl-5 space-y-2 text-gray-300 mt-2">
											<li>All your projects and generated scripts</li>
											<li>Your saved preferences and settings</li>
											<li>Any active subscriptions</li>
											<li>All associated data (cannot be recovered)</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						{/* Delete Button */}
						<div className="text-center">
							<button onClick={() => setIsModalOpen(true)} className="submit">
								Delete My Account
							</button>
						</div>
					</div>

					{/* Modal */}
					{isModalOpen && (
						// <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
						//   <div className="bg-gray-800 rounded-lg p-6 max-w-md w-full">
						//     <h2 className="text-xl font-semibold mb-4">
						//       Confirm Account Deletion
						//     </h2>

						//     <div className="space-y-4">
						//       <div>
						//         <label className="block text-sm font-medium text-gray-300 mb-1">
						//           Enter your password to confirm
						//         </label>
						//         <input
						//           type="password"
						//           value={password}
						//           onChange={(e) => setPassword(e.target.value)}
						//           className="w-full bg-gray-700 border border-gray-600 rounded-lg px-4 py-2 text-white focus:outline-none focus:ring-2 focus:ring-red-500"
						//           placeholder="Password"
						//         />
						//       </div>

						//       <div className="flex items-center">
						//         <input
						//           type="checkbox"
						//           id="consent"
						//           checked={consentChecked}
						//           onChange={(e) => setConsentChecked(e.target.checked)}
						//           className="rounded bg-gray-700 border-gray-600 text-red-600 focus:ring-red-500"
						//         />
						//         <label htmlFor="consent" className="ml-2 text-sm text-gray-300">
						//           I understand that this action cannot be undone and all my data will be permanently deleted
						//         </label>
						//       </div>

						//       <div className="flex space-x-3">
						//         <button
						//           onClick={handleDeleteAccount}
						//           disabled={loading}
						//           className="flex-1 bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded-lg font-semibold transition-colors disabled:opacity-50"
						//         >
						//           {loading ? (
						//             <span className="flex items-center justify-center">
						//               <svg className="animate-spin h-5 w-5 mr-2" viewBox="0 0 24 24">
						//                 <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
						//                 <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
						//               </svg>
						//               Deleting...
						//             </span>
						//           ) : (
						//             'Delete Account'
						//           )}
						//         </button>

						//         <button
						//           onClick={() => setIsModalOpen(false)}
						//           className="flex-1 bg-gray-600 hover:bg-gray-700 text-white px-4 py-2 rounded-lg font-semibold transition-colors"
						//         >
						//           Cancel
						//         </button>
						//       </div>
						//     </div>
						//   </div>
						// </div>

						<div className="modal-overlay">
							<div className="modal-container">
								<h2 className="modal-heading">Confirm Account Deletion</h2>
								<form onSubmit={handleDeleteAccount}>
									<div className="space-y-6">
										<div className="modal-input-container">
											<label htmlFor="email" className="modal-label">
												Enter your email
											</label>
											<input required type="email" id="email" value={username} onChange={(e) => setUsername(e.target.value)} className="modal-input" placeholder="Email" />
										</div>
										<div className="modal-input-container">
											<label htmlFor="password" className="modal-label">
												Enter your password
											</label>
											<input
												required
												type="password"
												id="password"
												value={password}
												onChange={(e) => setPassword(e.target.value)}
												className="modal-input"
												placeholder="Password"
											/>
										</div>

										<div className="modal-checkbox-container">
											<input required type="checkbox" id="consent" checked={consentChecked} onChange={(e) => setConsentChecked(e.target.checked)} className="modal-checkbox" />
											<label htmlFor="consent" className="modal-checkbox-label">
												I understand that my account will be deactivated for 30 days, during which I can recover it by logging in. After 30 days, my account and all data will
												be permanently deleted.
											</label>
										</div>

										<div className="modal-buttons-container">
											<button type="submit" disabled={loading || !consentChecked || !username || !password} className="modal-delete-button">
												{loading ? (
													<span className="modal-spinner">
														<svg className="animate-spin h-5 w-5 mr-2" viewBox="0 0 24 24">
															<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
															<path
																className="opacity-75"
																fill="currentColor"
																d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
															/>
														</svg>
														Deleting...
													</span>
												) : (
													"Delete Account"
												)}
											</button>

											<button onClick={() => setIsModalOpen(false)} className="modal-cancel-button">
												Cancel
											</button>
										</div>
									</div>
								</form>
							</div>
						</div>
					)}
					{showConfirmDeletion ? (
						<div className="modal-overlay">
							<div className="modal-container text-center">
								<div className="p-6">
									<h2 className="text-2xl font-bold mb-4 text-red-500">Account Deletion Scheduled</h2>

									<div className="bg-gray-800 p-4 rounded-lg mb-4">
										<p className="text-gray-300 mb-2">Reference ID:</p>
										<p className="font-mono text-white bg-gray-700 p-2 rounded">{referenceId}</p>
										<p className="text-gray-400 text-sm mt-2">Please save this ID for future reference</p>
									</div>

									<div className="space-y-4 text-gray-300">
										<p>Your account has been scheduled for deletion.</p>
										<p>You have 30 days to recover your account by logging in.</p>
										<p>After this period, all data will be permanently deleted.</p>
									</div>

									<div className="mt-6 text-gray-400">Redirecting to homepage in {countdown} seconds...</div>

									<button onClick={() => navigate("/")} className="mt-4 modal-cancel-button">
										Return to Home
									</button>
								</div>
							</div>
						</div>
					) : (
						<></>
					)}
				</div>
			</div>
			<FooterBanner />
			<Footer />
		</div>
	);
};

export default DeleteAccountWeb;
