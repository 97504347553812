import React from "react";
import LogoImg from "../assets/images/logo.png";
import Instagram from "../assets/images/Instagram.png";
import Facebook from "../assets/images/facebook.png";
import Twitter from "../assets/images/Twitter.png";
import Youtube from "../assets/images/youtube.png";
import Linkdn from "../assets/images/linkdn.png";

function Footer() {
	return (
		<div className="container">
			<footer className="mt-lg-5 mt-3">
				<div className="text-center">
					<a href="/">
						<img src={LogoImg} alt="Logo" className="img-fluid footer-logo" width="140" height="auto" />
					</a>
				</div>
				<ul className="nav justify-content-center pb-3 ">
					<li className="nav-item">
						<a href="/about" className="nav-link px-4">
							About Us
						</a>
					</li>
					{/* <li className="nav-item"><a href="#" className="nav-link px-4">Media </a></li> */}
					<li className="nav-item">
						<a href="/contact" className="nav-link px-4">
							Contact Us
						</a>
					</li>
				</ul>
				<ul className="nav justify-content-center pb-1">
					<li className="nav-item social-icons">
						<a href="https://www.instagram.com/weareflickvid/" className="nav-link px-2 text-muted">
							<img src={Instagram} alt="Instagram" className="img-fluid" width="40" height="40" />
						</a>
					</li>
					<li className="nav-item social-icons">
						<a href="#" className="nav-link px-2 text-muted">
							<img src={Facebook} alt="Instagram" className="img-fluid" width="40" height="40" />
						</a>
					</li>
					<li className="nav-item social-icons">
						<a href="#" className="nav-link px-2 text-muted">
							<img src={Twitter} alt="Instagram" className="img-fluid" width="40" height="40" />
						</a>
					</li>
					<li className="nav-item social-icons">
						<a href="#" className="nav-link px-2 text-muted">
							<img src={Youtube} alt="Instagram" className="img-fluid" width="40" height="40" />
						</a>
					</li>
					<li className="nav-item social-icons">
						<a href="#" className="nav-link px-2 text-muted">
							<img src={Linkdn} alt="Instagram" className="img-fluid" width="40" height="40" />
						</a>
					</li>
				</ul>
				<div className="d-flex justify-content-between py-4 mt-4 footer-border-top">
					<p className="mb-0 copyright-footer">Copyright © 2024 FlickVid</p>
					<ul className="list-unstyled d-flex">
						{/* <li className="ms-3">
        Legal Disclaimer                  
           </li> */}
						<li className="ms-3">
							<a href="/privacy">Privacy Policy </a>
						</li>
						<li className="ms-3">
							<a href="/terms-and-condition">Terms and Conditions</a>
						</li>
					</ul>
				</div>
			</footer>
		</div>
	);
}

export default Footer;
